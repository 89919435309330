@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap');

* {
    font-family: 'Montserrat';
    margin: 0;
    
}

body {
    background-image: linear-gradient(to right, #000, #000);
    color: #fff;
    overflow-x: hidden;
    button{
        font-family: 'Montserrat' !important;
    }
}

h1 {
    font-size: 32pt;
}

span {
    font-weight: bold;
}

.title {
    font-size: 35px;
}

.main {
    text-align: center;
    margin-top: 7%;
}

button {
    color: red;
}